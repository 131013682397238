@import "@/assets/scss/style.scss";

.wrapper {
    margin: auto;
    width: 80%;
    margin-top: 5%;
    height: auto;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    padding: 20px;

    @media screen and (max-width: 500px) {
        width: 100%;
        padding: 10px;
    }
}

.innerWrapper {
    height: 100%;
    border-style: none;
}

.ant-layout-content {
    overflow: hidden;
    border-left: 0px solid $grey-4;
}
.menu {
    border-style: none;
}
.sider {
    background-color: $white-two;
    border-style: none;
    flex: 0 0 150px !important;
    max-width: 150px !important;
    min-width: 150px !important;
    width: 150px !important;
}
.ant-layout {
    height: 100%;
}

.ant-layout-content {
    background-color: #fff;
}

.ant-menu-inline .ant-menu-item::after {
    left: 0;
    right: auto;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6f7ff00;
}

.menu-title {
    padding-bottom: 25px;
    margin-left: -60px;
    font-size: 0.9rem;
    font-weight: 450;
    color: #262626;
}

.product-div {
    width: 70px;
    margin: 15px 0px;
    text-align: center;
    transition: transform 0.2s; /* Animation */
}

.product-div:hover {
    transform: scale(1.1);
}
.product-div .icon {
    width: 100%;
    justify-content: center;
    justify-self: center;
}

.product-div .text {
    display: inline-block;
    width: 100%;
    text-align: center;
    white-space: initial;
    word-wrap: break-word;
}
